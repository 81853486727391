import React from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
  Section,
} from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"  
import theChakraBalancingCollection from "../../fixtures/chakra-balancing-collection"
import AudioCard from "../../components/App/audioCard"

import {
  StyledAudio,
} from "../../styledComponents/app/audioCard"

const TheChakraBalancingCollection = ({ data, location }) => {
  const identity = useIdentityContext()
  const collection = theChakraBalancingCollection
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <LayoutNoStripe location={location} isHomePage>
      {identity.user ?
        <HeroBanner 
          image="https://www.karinagrant.co.uk/static/1f035ec462f9b0ede0d98e9197063155/d81e8/the-essential-mindfulness-collection.webp" 
          backgroundPosition="0 0" 
          largeBackgroundPosition="0 -260px" 
          largeBackgroundSize="100%"
        >
          <HeroText>
            <HeroHeader>
              {collection[0].programName}
            </HeroHeader>
          </HeroText>
        </HeroBanner>
      :
        <Section>
          <StyledAudio>
          {
            collection[0].audioFiles.map(audio => {
              return (
                <AudioCard 
                  key={audio.title + `-1`}
                  title={audio.title}
                  color={audio.color}
                  image={collection[0].featuredImage}
                  audioMp3={audio.audioMp3}
                  duration={audio.duration}
                />     
              )
            })
          }
          </StyledAudio>
        </Section>
      }
    </LayoutNoStripe>
    </>
  )
}

export default TheChakraBalancingCollection

